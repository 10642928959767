import { dequal as deepEqual } from "dequal"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import { fetchData, FetchData } from "../utils/fetchData"
import { createStatus, useStatus } from "./status"
import { useSignal } from "./useSignal"
import { useAuth } from '../components/authContext'

function useDeepCompareMemoize<T>(value: T, deps: any[] = []) {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current, ...deps]);
}

const PAGE_SIZE = 20;
export function usePaginatedRequest<T>(
  fetchConfig: FetchData.PaginatedApiFunction<T>,
  options: RequestOptions = {}
) {
  const { authToken } = useAuth()
  const pause = options.disabled;
  const { url } = fetchConfig as FetchData.Args;
  const [items, setItems] = useState<T[]>([]);
  const [status, updateStatus] = useStatus<
    FetchData.PaginatedResponse<T>,
    true
  >("GET" + url);
  const [nextCursor, setNextCursor] = useState<string | null>();
  const loadingRef = useRef(false);

  const typedFetchConfig = useDeepCompareMemoize(fetchConfig) as FetchData.Args;

  const loadRequestIndex = useRef(0);

  const load = useCallback(async () => {
    if (nextCursor === null || pause) {
      return;
    }

    const reqIndex = ++loadRequestIndex.current;

    try {
      updateStatus({ pending: true });

      const res = await fetchData<FetchData.PaginatedResponse<T>>({
        ...typedFetchConfig,
        query: {
          ...typedFetchConfig.query,
          limit: PAGE_SIZE,
          cursor: nextCursor,
        },
        authToken
      });

      if (reqIndex === loadRequestIndex.current) {
        setNextCursor(res.next_cursor);
        setItems((prev) => [...prev, ...res.data]);
        updateStatus({ success: res });
      }
    } catch (e) {
      updateStatus({ error: e });
    }
  }, [typedFetchConfig, updateStatus, pause, nextCursor, authToken]);

  let itemsLength = items.length;
  useEffect(() => {
    if (itemsLength === 0) {
      // initial load
      load();
    }
  }, [load, itemsLength]);

  useEffect(() => {
    loadingRef.current = Boolean(status.pending);
  }, [status.pending]);

  const loadMore = useCallback(() => {
    if (!loadingRef.current) {
      load();
    }
  }, [load]);

  const refresh = useCallback(() => {
    setNextCursor(undefined);
    setItems([]);
  }, []);

  return {
    items,
    loadMore,
    refresh,
    hasMore: nextCursor !== null,
  };
}

export function useRequest<T>(
  fetchConfig: FetchData.ApiFunction<T>,
  options: RequestOptions = {},
  deps: any[] = []
) {
  const { authToken } = useAuth()
  const { url, type } = fetchConfig as FetchData.Args;
  const { onSuccess, onError, requiresAuth } = options;
  const disabled = Boolean(options.disabled) || (requiresAuth ? !authToken : false);
  const [status, updateStatus] = useStatus<T, true>((type ?? "GET") + url);
  const [data, setData] = useState<T | null>(null);
  const [refreshKey, refresh] = useSignal();

  // any request that isn't GET requires submit to be called
  const [submitted, setSubmit] = useState(false);

  const requiresSubmit = options.requiresSubmit ?? (type && type !== "GET");

  useEffect(() => {
    if (status.success) {
      onSuccess?.();
    } else if (status.error) {
      onError?.();
    }
  }, [status.success, status.error]);

  const submit = useCallback(() => {
    if (!disabled) {
      setSubmit(true);
    }
  }, [disabled]);

  useDeepCompareEffect(() => {
    if (disabled || (requiresSubmit && !submitted)) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    const cancelRef = { current: false };

    updateStatus({ pending: true });

    fetchData<T>({
      authToken,
      ...fetchConfig,
      signal,
    })
      .then((res) => {
        if (!cancelRef.current) {
          setSubmit(false);
          setData(res);
          updateStatus({ success: res });
        }
      })
      .catch((err) => {
        if (!cancelRef.current) {
          setSubmit(false);
          updateStatus({ error: err });
        }
      });

    return () => {
      controller.abort();
      cancelRef.current = true;
    };
  }, [fetchConfig, refreshKey, disabled, submitted, requiresSubmit, ...deps]);

  return {
    data,
    status,
    refresh,
    submit,
  };
}

type RequestOptions = {
  disabled?: boolean;
  requiresSubmit?: boolean;
  onSuccess?: () => any;
  onError?: () => any;
  requiresAuth?: boolean
};

export async function makeRequest<T>(fetchConfig: FetchData.ApiFunction<T>) {
  let data: T | null = null;
  const { url, type } = fetchConfig as FetchData.Args;
  const { status, updateStatus } = createStatus<T, true>((type ?? "GET") + url);

  try {
    data = await fetchData<T>(fetchConfig);
    updateStatus({ success: data });
  } catch (e) {
    updateStatus({ error: e });
  }

  return {
    data,
    status,
  };
}

export const api = {
  useRequest,
  makeRequest,
  usePaginatedRequest,
};
