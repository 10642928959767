import { useCallback, useEffect, useRef, useState } from "react";
// import { useMessage } from "../components/Message"
// import { ReactChildren } from "../types/react"
// import { v4 as uuid } from "uuid"
// import useDeepCompareEffect from "use-deep-compare-effect"

export declare namespace Status {
  export type EmptyStatus = {
    pending: null;
    error: null;
    success: null;
    id: null;
  };

  // Forces the user to only pass one
  // status type when updating a status.
  // For example, { success: data } works,
  // but { success: data, error: data } doesn't.
  // This is useful for making updates to status.
  interface PartialPending<T> extends Partial<Omit<EmptyStatus, "pending">> {
    pending: T;
  }
  interface PartialError extends Partial<Omit<EmptyStatus, "error">> {
    error: any;
  }
  interface PartialSuccess<T> extends Partial<Omit<EmptyStatus, "success">> {
    success: T;
  }
  export type UpdateStatus<S = any, P = true> =
    | PartialSuccess<S>
    | PartialPending<P>
    | PartialError;

  // A complete status where one value exsits
  // and the rest are null. For example,
  // { success: data, error: null, pending: null }.
  interface Pending<T> extends Omit<EmptyStatus, "pending" | "id"> {
    pending: T;
    id: string | null;
  }
  interface Error extends Omit<EmptyStatus, "error" | "id"> {
    error: any;
    id: string | null;
  }
  interface Success<T> extends Omit<EmptyStatus, "success" | "id"> {
    success: T;
    id: string | null;
  }
  export type Status<S = any, P = true> = Success<S> | Pending<P> | Error;

  export type ReduxStatus = {
    pending: any;
    error: any;
    success: any;
    // TODO: rename this to id to match status
    actionName: string;
  };

  export type AnyStatus<S = any, P = any> =
    | EmptyStatus
    | Status<S, P>
    | ReduxStatus;
}

const EMPTY_STATUS: Status.EmptyStatus = {
  pending: null,
  error: null,
  success: null,
  id: null,
};

export function createStatus<S, P = true>(id: string | null = null) {
  const status: Status.Status<S, P> | Status.EmptyStatus = {
    ...EMPTY_STATUS,
    id,
  };

  function updateStatus(newStatus: Status.UpdateStatus<S, P>) {
    let key: keyof Status.EmptyStatus;
    for (key in EMPTY_STATUS) {
      if (key !== "id") {
        status[key] = newStatus[key] ?? EMPTY_STATUS[key];
      }
    }
  }

  function updateStatusId(newId: typeof id) {
    status.id = newId;
  }

  return {
    status: status as Status.Status<S, P> | Status.EmptyStatus,
    updateStatus,
    updateStatusId,
  };
}

export function useStatus<S, P = true>(id: string | null = null) {
  const [, setSignal] = useState(0);
  const {
    status,
    updateStatus: updateStatusInternal,
    updateStatusId,
  } = useRef(createStatus<S, P>(id)).current;

  const updateStatus = useCallback(
    (newStatus: Status.UpdateStatus<S, P>) => {
      updateStatusInternal({
        ...newStatus,
      });
      setSignal((i) => (i + 1) % 2);
    },
    [updateStatusInternal]
  );

  useEffect(() => {
    updateStatusId(id);
  }, [id, updateStatusId]);

  return [status, updateStatus] as const;
}

// export const useStatusMsg = (
//   status: Status.AnyStatus,
//   msgs: {
//     pending?: ReactChildren<string>
//     error?: ReactChildren<string>
//     success?: ReactChildren<string>
//   },
//   key?: string
// ) => {
//   const msg = useMessage()
//   const uuidRef = useRef(uuid())

//   useDeepCompareEffect(() => {
//     const tag =
//       key ??
//       (status as Status.ReduxStatus).actionName ??
//       (status as Status.Status).id ??
//       uuidRef.current

//     if (status.pending && msgs.pending) {
//       msg.clear({ tag })
//       msg.pending({
//         content: msgs.pending,
//         duration: 0,
//         tag,
//       })
//     } else if (status.error && msgs.error) {
//       msg.clear({ tag })
//       msg.error({
//         content: msgs.error,
//         tag,
//       })
//     } else if (status.success && msgs.success) {
//       msg.clear({ tag })
//       msg.success({
//         content: msgs.success,
//         tag,
//       })
//     } else {
//       msg.clear({ tag })
//     }
//   }, [
//     key,
//     // For some reason deep compare seems to
//     // skip objects with the same identity even
//     // if the values have changed. Cloning status
//     // forces the hook to look at the values.
//     { ...status },
//     msgs,
//     msg,
//   ])
// }
