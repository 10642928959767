import { useState, useCallback } from "react"

/**
 * Returns a function that can be used
 * to signal a component to re-render.
 * The value of this hook doesn't really
 * matter, but this implementation flips,
 * the value of signal between 0 and 1.
 */
export function useSignal() {
  const [signal, setSignal] = useState(0)
  const update = useCallback(() => {
    setSignal((i) => (i + 1) % 2)
  }, [])
  return [signal, update] as const
}