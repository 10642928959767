import {
  useCallback,
  createContext,
  useState,
  useContext,
  ComponentProps,
} from "react";
import { ReactChild, ReactChildren } from "../types";
import { Button } from "./wrapped";
import { v4 as uuid } from "uuid";
import { Heading, Text } from "./Text";
import styled from "styled-components";
import { config } from "../config";
import { IoMdClose } from "react-icons/io";

export const Backdrop = styled.div<{
  $visible: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(28 28 28 / 60%);
  ${({ $visible }) => (!$visible ? "display: none;" : "")}
`;

const AlertWrap = styled.div`
  width: 350px;
  max-width: 100%;
  text-align: center;
  background: white;
  --btl-text: black;
  padding: 15px;
  position: relative;

  & > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > * {
  }
`;

export declare namespace Alert {
  export type Type = "success" | "error" | "info" | "warning" | "pending";

  export interface AlertConfig {
    title: string;
    content?: string | ReactChildren;
    buttons?: ComponentProps<typeof Button>[];
    type?: Type;
    onClose?: () => any;
  }

  export interface AlertState extends AlertConfig {
    id: string;
  }
}

function generateAlertState(
  alertConfig: Alert.AlertConfig | Alert.AlertConfig["title"]
): Alert.AlertState {
  const config: Alert.AlertConfig =
    typeof alertConfig === "string" ? { title: alertConfig } : alertConfig;
  return {
    type: "info",
    ...config,
    id: uuid(),
  };
}

const Context = createContext<{
  alert: Alert.AlertState | null;
  updateAlert: (msg: Alert.AlertConfig | Alert.AlertConfig["title"]) => any;
  clearAlert: () => any;
}>({
  alert: null,
  updateAlert: () => {},
  clearAlert: () => {},
});

export function AlertProvider({ children }: { children: ReactChild }) {
  const [alert, setAlert] = useState<Alert.AlertState | null>(null);

  const updateAlert = useCallback(
    (alertConfig: Alert.AlertConfig | Alert.AlertConfig["title"]) =>
      setAlert(generateAlertState(alertConfig)),
    []
  );

  const clearAlert = useCallback(() => {
    setAlert(null);
  }, []);

  return (
    <Context.Provider
      value={{
        alert,
        updateAlert,
        clearAlert,
      }}
    >
      {children}
      <Backdrop $visible={Boolean(alert)}>
        {alert && (
          <AlertWrap className="btl-alert">
            <button
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                background: "transparent",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={clearAlert}
            >
              <IoMdClose color={config.colors.text} size={25} />
            </button>

            <Heading style={{ textAlign: "center" }}>{alert.title}</Heading>
            {alert.content && (
              <Text paragraph style={{ textAlign: "center" }}>
                {alert.content}
              </Text>
            )}
            {alert.buttons && (
              <FlexRow>
                {alert.buttons.map((btn, i) => (
                  <Button
                    key={i}
                    onClick={clearAlert}
                    {...btn}
                    style={{ margin: "0 5px", ...btn.style }}
                  />
                ))}
              </FlexRow>
            )}
          </AlertWrap>
        )}
      </Backdrop>
    </Context.Provider>
  );
}

export function useAlert() {
  const { updateAlert, clearAlert } = useContext(Context);

  const createAlertDispatcherOfType = useCallback(
    (type: Alert.AlertConfig["type"]) => {
      return (
        alertConfig:
          | Omit<Alert.AlertConfig, "type">
          | Alert.AlertConfig["title"]
      ) => {
        updateAlert({
          ...(typeof alertConfig === "string"
            ? { title: alertConfig }
            : alertConfig),
          type,
        });
      };
    },
    [updateAlert]
  );

  return {
    dispatch: updateAlert,
    success: createAlertDispatcherOfType("success"),
    error: createAlertDispatcherOfType("error"),
    info: createAlertDispatcherOfType("info"),
    warning: createAlertDispatcherOfType("warning"),
    pending: createAlertDispatcherOfType("pending"),
    clear: clearAlert,
  };
}
