import { useEffect, useMemo, useState } from "react";
import { useModule } from "../utils/module";
import { Select } from './wrapped'

interface StateSelectorProps
  extends Pick<JSX.IntrinsicElements["select"], "name"> {
  zipCode?: string;
}

export function StateSelector({ zipCode, ...selectProps }: StateSelectorProps) {
  const [value, setValue] = useState("");

  const UsaStates = useModule(() => import("usa-states"))?.default.UsaStates;
  const zipState = useModule(() => import("zip-state"))?.default;

  const usStates = useMemo(
    () => (UsaStates ? new UsaStates() : undefined),
    [UsaStates]
  );

  useEffect(() => {
    if (zipState && zipCode && !value) {
      const code = zipState?.(zipCode);
      const state = usStates?.states.find((s) => s.abbreviation === code);
      if (state) {
        setValue(state.abbreviation);
      }
    }
  }, [zipCode, zipState, usStates, value]);

  return (
    <Select
      {...selectProps}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      {usStates?.states.map((state) => (
        <option value={state.abbreviation}>{state.name}</option>
      ))}
    </Select>
  );
}
