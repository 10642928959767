import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { API, ReactChildren } from "../types";
import { api, urls } from "../utils";
import { useAnalytics } from "./Analytics";
import { Context, useAuth, useIsLoggedIn } from "./authContext";
import { Form, FormItem, useForm } from "./Form";
import { useMessage } from "./Message";
import { Heading, Text } from "./Text";
import { Button, Input } from "./wrapped";
import { FocusReset } from "./FocusReset";

declare global {
  interface Window {
    BTL_AUTH_TOKEN?: string;
  }
}

const FlexCol = styled.div`
  display: grid;
  grid-template-columns: 1;
  row-gap: 10px;

  & > * {
    grid-column: span 1;
  }
`;

function LoginForm() {
  const { setAuthToken } = useAuth();
  const [authMethod, setAuthMethod] = useState<string>();
  const msg = useMessage();

  const form = useForm<{
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
  }>({
    onSubmit: async (data) => {
      switch (authMethod) {
        case "NEW_USER":
          {
            const { data: registerData, status } = await api.makeRequest<{
              token: string;
            }>({
              type: "POST",
              url: urls.api("/register"),
              body: {
                email: data.email,
                password: data.password,
                confirmPassword: data.confirmPassword,
                firstName: data.firstName,
                lastName: data.lastName,
                name: `${data.firstName} ${data.lastName}`,
              },
            });
            if (registerData) {
              setAuthToken(registerData.token);
            } else if (status.error) {
              msg.error(status.error.message);
            } else {
              msg.error("An unexpectd error occured");
            }
          }
          break;
        case "AUTH_PASSWORD":
          {
            const { data: authLoginData, status } = await api.makeRequest<{
              token: string;
            }>({
              type: "POST",
              url: urls.api("/auth/login"),
              body: {
                email: data.email,
                password: data.password,
              },
            });
            if (authLoginData) {
              setAuthToken(authLoginData.token);
            } else if (status.error.message) {
              msg.error(status.error.message);
            }
          }
          break;
        default: {
          const { data: authMethodData, status } = await api.makeRequest<{
            method: string;
          }>({
            type: "POST",
            url: urls.api("/auth/method"),
            body: {
              email: data.email,
            },
          });

          if (status.error) {
            setAuthMethod("NEW_USER");
          } else {
            setAuthMethod(authMethodData?.method);
          }
        }
      }
    },
  });

  return (
    <Form
      {...form}
      style={{ width: "100%", maxWidth: 350, margin: "0 auto", marginTop: 20 }}
    >
      <FlexCol>
        <Heading>
          {authMethod === undefined && "Get started"}
          {authMethod === "AUTH_PASSWORD" && "Login"}
          {authMethod === "AUTH_EMAIL" && "Check Your Email"}
          {authMethod === "NEW_USER" && "Sign up"}
        </Heading>
        {authMethod !== "AUTH_EMAIL" && (
          <FormItem label="Email">
            <Input
              placeholder="keenan@breakthelove.com"
              name="email"
              required
              readOnly={authMethod !== undefined}
              autoFocus
            />
          </FormItem>
        )}
        {authMethod === "NEW_USER" && (
          <>
            <FormItem label="First name">
              <Input placeholder="Keenan" name="firstName" required autoFocus />
            </FormItem>
            <FormItem label="Last name">
              <Input placeholder="Smith" name="lastName" required />
            </FormItem>
            <FormItem label="Password">
              <Input type="password" name="password" required />
            </FormItem>
            <FormItem label="Confirm password">
              <Input type="password" name="confirmPassword" required />
            </FormItem>
          </>
        )}
        {authMethod === "AUTH_PASSWORD" && (
          <FormItem label="Password">
            <Input type="password" name="password" required autoFocus />
          </FormItem>
        )}
        {authMethod === "AUTH_EMAIL" ? (
          <Text>
            An email has been sent to {form.data.email} to verify your account.
            Complete the form and refresh this page.
          </Text>
        ) : (
          <>
            <Button>Next</Button>
            {authMethod && (
              <Button type="button" onClick={() => setAuthMethod(undefined)}>
                Change email
              </Button>
            )}
          </>
        )}
      </FlexCol>
    </Form>
  );
}

export function Auth({
  children,
  ott,
  unauthorized,
}: {
  children: ReactChildren;
  ott?: boolean;
  unauthorized?: ReactChildren;
}) {
  const isLoggedIn = useIsLoggedIn();
  const { ottError } = useAuth();

  if (ott && ottError && !isLoggedIn) {
    return <>{unauthorized}</>;
  }

  if (!isLoggedIn) {
    return ott ? null : <LoginForm />;
  }

  return (
    <>
      <FocusReset />
      {children}
    </>
  );
}

export function AuthProvider({ children }: { children: ReactChildren }) {
  const [authToken, setAuthToken] = useState<string>();

  const [search] = useSearchParams();
  const [ott, setOtt] = useState<string>();
  const [ottError, setOttError] = useState(false);

  useEffect(() => {
    if (authToken) {
      return;
    }

    const ott = search.get("ott");
    let cancled = false;

    if (ott) {
      api
        .makeRequest<{ authToken: string }>({
          type: "POST",
          url: urls.api("/sso/ott/auth"),
          body: {
            ott,
          },
        })
        .then(({ data, status }) => {
          if (status.error) {
            setOttError(true);
          } else if (!cancled && data) {
            setAuthToken(data.authToken);
            setOtt(ott);
            setOttError(false);
          }
        });
    } else {
      setOttError(true);
    }

    return () => {
      cancled = true;
    };
  }, [search, authToken]);

  const analytics = useAnalytics();

  const { data: user } = api.useRequest<API.User>(
    {
      url: urls.api("/user"),
      authToken,
    },
    {
      disabled: !authToken,
    }
  );

  useEffect(() => {
    if (user) {
      analytics.identify(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        type: user.type,
      });
    }
  }, [user, analytics]);

  return (
    <Context.Provider
      value={{
        authToken,
        setAuthToken,
        user: user ?? undefined,
        ottError,
        ott,
      }}
    >
      {children}
    </Context.Provider>
  );
}
