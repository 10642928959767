import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { config } from "../config";
import { ReactChildren } from "../types";

const sharedStyles = css`
  & {
    color: ${config.colors.text};
  }

  &.TRUNCATE {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: var(--NUM_OF_LINES_VAR);
    word-wrap: break-word;
  }

  &.btl-paragraph {
    line-height: 1.4em;
  }

  &.btl-paragraph ~ &.btl-paragraph {
    margin-top: 0.8em;
  }
`;

const HeadingStyle = styled.span`
  ${sharedStyles}

  display: block;
  font-weight: bold;
  text-align: start;
`;

const TextStyle = styled.span`
  ${sharedStyles}

  display: inline-block;
  text-align: start;
`;

export function Heading({
  children,
  style,
  className,
  numOfLines,
  themeColor,
  size = 1,
}: {
  children: ReactChildren;
  style?: CSSProperties;
  className?: string;
  numOfLines?: number;
  themeColor?: keyof typeof config.colors;
  size?: number;
}) {
  const cssVars: Record<string, number> = {
    "--NUM_OF_LINES_VAR": numOfLines ?? 0,
  };

  return (
    <HeadingStyle
      style={{
        ...cssVars,
        ...(themeColor ? { color: config.colors[themeColor] } : {}),
        fontSize: `calc(var(--btl-font-size, 1rem) * ${size})`,
        ...style,
      }}
      className={[
        numOfLines !== undefined ? "TRUNCATE" : "",
        "btl-heading",
        className,
      ].join(" ")}
    >
      {children}
    </HeadingStyle>
  );
}

export function Text({
  children,
  style,
  className,
  numOfLines,
  themeColor,
  size = 1,
  paragraph,
}: {
  children: ReactChildren;
  style?: CSSProperties;
  className?: string;
  numOfLines?: number;
  themeColor?: keyof typeof config.colors;
  size?: number;
  paragraph?: boolean;
}) {
  const cssVars: Record<string, number> = {
    "--NUM_OF_LINES_VAR": numOfLines ?? 0,
  };

  return (
    <TextStyle
      style={{
        ...cssVars,
        ...(themeColor ? { color: config.colors[themeColor] } : {}),
        fontSize: `calc(var(--btl-font-size, 1rem) * ${size})`,
        ...style,
      }}
      className={[
        numOfLines !== undefined ? "TRUNCATE" : "",
        paragraph ? "btl-paragraph" : "",
        "btl-text",
        className,
      ].join(" ")}
    >
      {children}
    </TextStyle>
  );
}
