import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  MemoryRouter,
  Route,
  Routes, useNavigate
} from "react-router-dom";
import styled from "styled-components";
import { AlertProvider } from "./components/Alert";
import { AnalyticsProvider } from "./components/Analytics";
import { AuthProvider } from "./components/Auth";
import { FocusResetNav } from "./components/FocusReset";
import { MessageProvider } from "./components/Message";
import { config } from "./config";
import { partnersConfig } from "./partners/config";

const GlobalStyles = styled.div`
  * {
    box-sizing: border-box;
  }
`;

const ToppingRoseRouter = lazy(() => import("./partners/ToppingRose"));
const EquinoxHamptonsRouter = lazy(() => import("./partners/EquinoxHamptons"));
const AmericanExpressRouter = lazy(() => import("./partners/AmericanExpress"));
const DebugRouter = lazy(() => import("./partners/Debug"));

function Router() {
  return (
    <AuthProvider>
      <FocusResetNav />
      <Suspense>
        <Routes>
          <Route
            path={`${partnersConfig.toppingRose.routerRoot}/*`}
            element={<ToppingRoseRouter />}
          />
          <Route
            path={`${partnersConfig.equinox.routerRoot}/*`}
            element={<EquinoxHamptonsRouter />}
          />
          <Route
            path={`${partnersConfig.americanExpress.routerRoot}/*`}
            element={<AmericanExpressRouter />}
          />
          <Route path="debug/*" element={<DebugRouter />} />
        </Routes>
      </Suspense>
    </AuthProvider>
  );
}

const clients = {
  TOPPING_ROSE: "/topping-rose/programs",
  TOPPING_ROSE_CAROUSEL: "/topping-rose/programs-carousel",
};

function InitRoute({ initialRoute }: { initialRoute: string }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (initialRoute) {
      navigate(initialRoute);
    }
  }, [initialRoute]);

  return null;
}

function App({
  initialRoute,
  routerType = "MEMORY",
}: {
  initialRoute?: string;
  routerType?: string;
}) {
  initialRoute = clients[initialRoute as keyof typeof clients];

  return (
    <AlertProvider>
      <MessageProvider>
        <AnalyticsProvider>
          <GlobalStyles>
            {routerType === "BROWSER" && (
              <>
                <BrowserRouter>
                  <InitRoute initialRoute={initialRoute} />
                  <Router />
                </BrowserRouter>
              </>
            )}
            {routerType === "HASH" && (
              <HashRouter>
                <InitRoute initialRoute={initialRoute} />
                <Router />
              </HashRouter>
            )}
            {routerType === "MEMORY" && (
              <MemoryRouter
                initialEntries={initialRoute ? [initialRoute] : undefined}
              >
                <Router />
              </MemoryRouter>
            )}
            <a
              href="//breakthelove.com"
              style={{
                color: config.colors.textMuted,
                textAlign: "center",
                display: "block",
                margin: "10px 0",
                textDecoration: "none",
              }}
            >
              Powered by Break the Love
            </a>
          </GlobalStyles>
        </AnalyticsProvider>
      </MessageProvider>
    </AlertProvider>
  );
}

export default App;
