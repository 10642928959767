import { useState, useEffect } from "react"

export function useModule<T>(moduleImport: () => Promise<T>) {
  const [module, setModule] = useState<T>()

  useEffect(
    () => {
      let canceled = false
      moduleImport().then?.((res) => {
        if (!canceled) {
          setModule(res)
        }
      })
      return () => {
        canceled = true
      }
    },
    // You really shouldn't be dynamically chaining the module that gets
    // imported, so we will assume the moduleImport function doesn't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return module
}