import { useEffect } from "react";
import Analytics from "analytics";
// @ts-ignore
import segmentPlugin from "@analytics/segment";
import { useLocation } from "react-router-dom";
import { ReactChildren } from "../types";
import { config } from "../config";

const analytics = Analytics({
  plugins: config.segmentWriteKey
    ? [
        segmentPlugin({
          writeKey: config.segmentWriteKey,
        }),
      ]
    : [],
});

export const useAnalytics = () => analytics;

export function TrackPageViews({ partner }: { partner?: string }) {
  const { pathname, search, hash } = useLocation();
  const url = `${config.appRoot}${pathname}${search}`;

  useEffect(() => {
    analytics.page({
      url,
      path: pathname,
      hash,
      search,
      properties: {
        embedPartner: partner,
      },
    });
  }, [url, pathname, search, hash, partner]);

  return null;
}

export function AnalyticsProvider({ children }: { children: ReactChildren }) {
  return <>{children}</>;
}
