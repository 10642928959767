import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function FocusReset({ focusKey }: { focusKey?: string }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.blur();
  }, [focusKey]);

  return <div ref={ref} tabIndex={-1} />;
}

export function FocusResetNav() {
  const { pathname } = useLocation();
  return <FocusReset focusKey={pathname} />;
}
