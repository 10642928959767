import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  createContext,
  CSSProperties,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { config } from "../config";
import { ReactChildren } from "../types";
import { Grid } from "./Grid";
import { Input } from "./wrapped";
import { StateSelector } from "./StateSelector";
import { CountrySelector } from "./CountrySelector";
import { Text } from "./Text";

export const HIDDEN_SUBMIT_BTN_CLASS = "HIDDEN_SUBMIT_BTN";

const Context = createContext({
  attemptedSubmit: false,
});

const HijackFormItem = styled.div<{ $color: keyof typeof config.colors }>`
  display: flex;
  flex-direction: column;
  &&& input {
    ${({ $color }) => ($color ? `border-color: ${config.colors.border};` : "")}
  }
`;

export declare namespace Form {
  interface Props extends ReturnType<typeof useForm> {
    children: ReactChildren;
    style?: CSSProperties;
  }

  type ValidateState = "error" | "warning" | "success" | "help";

  type ItemProps = {
    children: ReactChildren;
    statusMessages?: Partial<Record<ValidateState, ReactChildren>>;
    activeStatus?: Partial<Record<ValidateState, boolean>>;
    label?: string;
    required?: boolean;
    boldLabel?: boolean;
    style?: CSSProperties;
    enableRequiredHelpText?: boolean;
  };
}

export function Form({
  children,
  // onChange,
  onSubmit,
  attemptedSubmit,
  formRef,
  style,
}: Form.Props) {
  return (
    <Context.Provider value={{ attemptedSubmit }}>
      <form onSubmit={onSubmit} ref={formRef} style={style}>
        {children}
        <button
          type="submit"
          className={HIDDEN_SUBMIT_BTN_CLASS}
          style={{ display: "none" }}
          aria-hidden
        >
          hidden submit
        </button>
      </form>
    </Context.Provider>
  );
}

export function FormItem({
  activeStatus = {},
  statusMessages = {},
  children,
  label,
  required,
  enableRequiredHelpText = required,
  boldLabel,
  style,
}: Form.ItemProps) {
  const [isEmpty, setIsEmpty] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputWrap = ref.current;
    if (inputWrap) {
      const input = inputWrap.querySelector("[name]");
      if (input) {
        const handleChange = () => {
          // @ts-ignore
          setIsEmpty(!input.value);
        };
        handleChange();
        input.addEventListener("change", handleChange);
        return () => {
          input.removeEventListener("change", handleChange);
        };
      }
    }
  });

  const { attemptedSubmit } = useContext(Context);

  const status = Object.entries(activeStatus).find(
    ([, value]) => value
  )?.[0] as Form.ValidateState | undefined;

  // Error is not a color so we replace it with danger. "" is also not a color
  let color: keyof typeof config.colors;

  switch (status) {
    case "error":
      color = "danger";
      break;
    case "help":
      color = "textMuted";
      break;
    default:
      color = status ?? "textMuted";
  }

  const warnEmpty = enableRequiredHelpText && required && isEmpty && attemptedSubmit;
  const warmEmptyText = warnEmpty ? `${label} is required` : "";

  if (warnEmpty && !status) {
    color = "danger";
  }

  return (
    <div style={style}>
      <Text
        style={{ marginBottom: 5, fontWeight: boldLabel ? "bold" : "normal" }}
      >
        {label}
        {required && (
          <span style={{ marginLeft: "0.3ch", color: config.colors.danger }}>
            *
          </span>
        )}
      </Text>
      <HijackFormItem $color={color} ref={ref}>
        {children}
      </HijackFormItem>
      {(status || statusMessages.help || warnEmpty) && (
        <Text
          style={{
            display: "block",
            marginTop: 5,
            color: config.colors[color],
          }}
        >
          {statusMessages[status ?? "help"] ?? warmEmptyText}
        </Text>
      )}
    </div>
  );
}

export function AddressFormItems({
  fieldNames = {},
  fieldLabels = {},
  required = false,
}: {
  fieldNames?: Partial<{
    addressLine1: string;
    addressLine2: string;
    city: string;
    postalCode: string;
    country: string;
    state: string;
  }>;
  fieldLabels?: Partial<{
    addressLine1: string;
    addressLine2: string;
    city: string;
    postalCode: string;
    country: string;
    state: string;
  }>;
  required?: boolean;
}) {
  const [zipCode, setZipCode] = useState<string>();

  return (
    <Grid.Row cols={2} spacing={15}>
      <Grid.Col xs={2}>
        <FormItem
          label={fieldLabels.addressLine1 ?? "Address line 1"}
          required={required}
        >
          <Input
            name={fieldNames.addressLine1 ?? "addressLine1"}
            placeholder="110 Baker Street"
          />
        </FormItem>
      </Grid.Col>

      <Grid.Col xs={2}>
        <FormItem label={fieldLabels.addressLine2 ?? "Address line 2"}>
          <Input
            name={fieldNames.addressLine2 ?? "addressLine2"}
            placeholder="Apt. 2"
          />
        </FormItem>
      </Grid.Col>

      <Grid.Col sm={1} xs={2}>
        <FormItem label={fieldLabels.city ?? "City"} required={required}>
          <Input name={fieldNames.city ?? "city"} placeholder="New York" />
        </FormItem>
      </Grid.Col>

      <Grid.Col sm={1} xs={2}>
        <FormItem
          label={fieldLabels.postalCode ?? "Postal code"}
          required={required}
        >
          <Input
            name={fieldNames.postalCode ?? "postalCode"}
            placeholder="00000"
            onChange={(e) => setZipCode(e.target.value)}
          />
        </FormItem>
      </Grid.Col>

      <Grid.Col sm={1} xs={2}>
        <FormItem label={fieldLabels.country ?? "Country"} required={required}>
          <CountrySelector
            name={fieldNames.country ?? "country"}
            defaultValue="US"
          />
        </FormItem>
      </Grid.Col>

      <Grid.Col sm={1} xs={2}>
        <FormItem label={fieldLabels.state ?? "State"} required={required}>
          <StateSelector name={fieldNames.state ?? "state"} zipCode={zipCode} />
        </FormItem>
      </Grid.Col>
    </Grid.Row>
  );
}

dayjs.extend(utc);

type FormJsonData = Record<string, string | boolean | File | Dayjs>;

function collectFormData(elm: HTMLFormElement) {
  const jsonData: Partial<FormJsonData> = {};

  elm.querySelectorAll("input,textarea").forEach((elm) => {
    const input = elm as HTMLInputElement;
    const name = input.name as keyof FormJsonData;

    if (name) {
      switch (input.type) {
        case "checkbox":
          jsonData[name] = input.checked;
          break;
        case "file":
          jsonData[name] = input.files?.[0];
          break;
        case "datetime-local":
          if (input.value) {
            jsonData[name] = dayjs.utc(input.value, "YYYY-MM-DDTHH:mm");
          }
          break;
        default:
          jsonData[name] = input.value;
      }
    }
  });

  return jsonData;
}

export function useForm<T extends FormJsonData>({
  onSubmit,
  alwaysShowErrors,
}: {
  onSubmit?: (data: Partial<T>) => any;
  alwaysShowErrors?: boolean;
}) {
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const ref = useRef<HTMLFormElement>(null);
  const [data, setData] = useState<Partial<T>>({});
  const [edited, setEdited] = useState<Record<string, boolean>>({});
  const submitRef = useRef(onSubmit);

  submitRef.current = onSubmit;

  const handleChange = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setAttemptedSubmit(true);
      submitRef.current?.(data);
    },
    [data]
  );

  useEffect(() => {
    const formElm = ref.current;

    if (formElm) {
      const handleChange = (e: Event) => {
        const target = e.target;

        setEdited((prev) => ({
          ...prev,
          [(target as any).name]: true,
        }));

        setData(collectFormData(formElm) as T);
      };

      formElm.addEventListener("change", handleChange);

      return () => {
        formElm.removeEventListener("change", handleChange);
      };
    }
  });

  return {
    formRef: ref,
    onSubmit: handleChange,
    data,
    edited: Object.keys(edited)
      .map((elm) => (elm as any).name)
      .filter(Boolean) as string[],
    attemptedSubmit: alwaysShowErrors || attemptedSubmit,
    submit: () => {
      (
        ref.current?.getElementsByClassName(
          HIDDEN_SUBMIT_BTN_CLASS
        )[0] as HTMLButtonElement
      )?.click();
    },
  };
}
