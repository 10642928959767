import qs from "query-string"

const queryParseOptions: qs.ParseOptions = {
  // In practice, arrays will be
  // stringified, but we enable this
  // for backwards compatibility
  arrayFormat: "index",
}

/**
 * Rewrite all non object values to string
 * except null and undefined to match query-string's
 * handling of first level object values.
 */
function rewriteMostNonObjectValuesToStrings(obj: any): any {
  // Null stays all null to match query-string
  if (obj === null) {
    return obj
  }
  // Any other non object value because a string
  if (typeof obj !== "object") {
    return String(obj)
  }

  // Array is an object, but we want to handle it differently
  if (Array.isArray(obj)) {
    return obj.map(rewriteMostNonObjectValuesToStrings)
  }

  // All non array objects (that also aren't null)
  const newObj: Record<string, any> = {}
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) {
      newObj[key] = rewriteMostNonObjectValuesToStrings(value)
    }
  })
  return newObj
}

function stringify(data: any) {
  const preparedData: any = {}

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      // As per query-string's recommendation
      // https://www.npmjs.com/package/query-string#user-content-nesting
      preparedData[key] = JSON.stringify(
        rewriteMostNonObjectValuesToStrings(value)
      )
    } else {
      preparedData[key] = value
    }
  })

  return qs.stringify(preparedData, queryParseOptions)
}

function parse(data: string | Record<string, any>) {
  const output: any = {}
  let parsedData: Record<string, any> = {}

  if (typeof data === "string") {
    parsedData = qs.parse(data, queryParseOptions)
  } else {
    parsedData = data
  }

  Object.entries(parsedData).forEach(([key, value]) => {
    output[key] = value
    try {
      // If value is a stringified object
      // it will have been parsed as a string
      if (typeof value === "string") {
        const parsed = JSON.parse(value)
        // Only parse out objects (and arrays)
        if (typeof parsed === "object") {
          output[key] = parsed
        }
      }
    } catch (e) {}
  })

  return output
}

export const queryString = {
  stringify,
  parse,
}