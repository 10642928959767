import { createContext, useContext } from "react";
import { API } from "../types";

export const Context = createContext<{
  authToken?: string;
  user?: API.User;
  setAuthToken: (token: string) => any;
  ottError: boolean;
  ott?: string
}>({
  setAuthToken: () => {},
  ottError: false
});

export function useAuth() {
  return useContext(Context);
}

export function useUser() {
  return useAuth().user;
}

export function useIsLoggedIn() {
  return Boolean(useAuth().authToken);
}
