import * as React from "react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillInfoCircle,
  AiFillWarning
} from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { config } from "../config";
import { ReactChildren } from "../types";

const DEFAULT_DURATION = 10000;
const FADE_OUT_DURATION = 5000;

export declare namespace Message {
  export type Type = "success" | "error" | "info" | "warning" | "pending";

  export interface MsgConfig {
    content: string;
    type?: Type;
    onClose?: () => any;
    duration?: number;
    tag?: string;
  }

  export interface MsgState extends MsgConfig {
    id: string;
    timeout: number;
    persist: boolean;
  }
}

const Spinner = styled(CgSpinner)`
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const MessageWrap = styled.div`
  z-index: 5000;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  max-width: 300px;
`;

const MessageStyle = styled.div<{ fadeOut: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  opacity: ${({ fadeOut }) => (fadeOut ? "0" : "1")};
  cursor: pointer;
  border: 1px solid gray;
  padding: 10px;
  background-color: white;
  margin-top: 10px;
  transition: opacity ${FADE_OUT_DURATION / 1000}s, width 10s;
`;

const Context = React.createContext<{
  messages: Message.MsgState[];
  updateMessages: (
    msg: Message.MsgConfig | Message.MsgConfig["content"]
  ) => any;
  clearMessages: (options: { tag?: string; id?: string }) => any;
}>({
  messages: [],
  updateMessages: () => {},
  clearMessages: () => {},
});

function Icon({ type }: Pick<Message.MsgConfig, "type">) {
  const style = { marginRight: "0.5ch", width: 30 };

  if (type === "success")
    return <AiFillCheckCircle color={config.colors.success} style={style} />;
  if (type === "warning")
    return <AiFillWarning color={config.colors.warning} style={style} />;
  if (type === "error")
    return <AiFillCloseCircle color={config.colors.danger} style={style} />;
  if (type === "pending")
    return <Spinner color={config.colors.text} style={style} />;

  return <AiFillInfoCircle style={style} />;
}

function generateMessageState(
  msg: Message.MsgConfig | Message.MsgConfig["content"]
): Message.MsgState {
  const config: Message.MsgConfig =
    typeof msg === "object" && msg?.hasOwnProperty("content")
      ? (msg as Message.MsgConfig)
      : { content: msg as string };
  return {
    type: "info",
    tag: "",
    ...config,
    persist: typeof config.duration === "number" && config.duration <= 0,
    timeout: Date.now() + (config.duration ?? DEFAULT_DURATION),
    id: uuid(),
  };
}

export function MessageProvider({ children }: { children: ReactChildren }) {
  const [messages, setMessages] = React.useState<Message.MsgState[]>([]);
  const [persistId, setPersistId] = React.useState("");

  // Periodically check for and close (filter out) expired messages
  React.useEffect(() => {
    const timeout = messages.reduce((acc, msg) => {
      return msg.persist
        ? acc
        : Math.min(msg.timeout - Date.now() - FADE_OUT_DURATION, acc);
    }, Number.MAX_SAFE_INTEGER);

    function filterOldMessages() {
      const expiredMessages: Message.MsgState[] = [];

      const filteredMessages = messages.filter((msg) => {
        const notExpired =
          msg.persist || msg.id === persistId || msg.timeout - Date.now() > 0;
        if (!notExpired) {
          expiredMessages.push(msg);
        }
        return notExpired;
      });

      expiredMessages.forEach((msg) => msg.onClose?.());
      setMessages(filteredMessages);
    }

    if (timeout < Number.MAX_SAFE_INTEGER) {
      const id = setTimeout(filterOldMessages, Math.max(timeout, 0));
      return () => {
        window.clearTimeout(id);
      };
    }
  }, [messages, persistId]);

  const updateMessages = React.useCallback(
    (msg: Message.MsgConfig | Message.MsgConfig["content"]) =>
      setMessages((m) => [...m, generateMessageState(msg)]),
    []
  );

  const clearMessages = React.useCallback(
    (options: { tag?: string; id?: string }) => {
      setMessages((messages) => {
        // clear messages with tag
        if (options.id || options.tag) {
          return messages.filter((msg) => {
            if (
              (msg.id && msg.id === options.id) ||
              (msg.tag && msg.tag === options.tag)
            ) {
              msg.onClose?.();
              return false;
            } else {
              return true;
            }
          });
        }

        // clear all messages
        else {
          messages.forEach((msg) => msg.onClose?.());
          return [];
        }
      });
    },
    []
  );

  return (
    <Context.Provider
      value={{
        messages,
        updateMessages,
        clearMessages,
      }}
    >
      <MessageWrap>
        {messages.map((msg) => (
          <MessageStyle
            key={msg.id}
            onClick={() => clearMessages({ id: msg.id })}
            fadeOut={
              !msg.persist &&
              msg.id !== persistId &&
              msg.timeout - Date.now() - FADE_OUT_DURATION <= 0
            }
            onMouseEnter={() => setPersistId(msg.id)}
            onMouseLeave={() => setPersistId("")}
          >
            <Icon type={msg.type} />
            {msg.content}
          </MessageStyle>
        ))}
      </MessageWrap>
      {children}
    </Context.Provider>
  );
}

export function useMessage() {
  const { updateMessages, clearMessages } = React.useContext(Context);

  const createMessageDispatcherOfType = React.useCallback(
    (type: Message.MsgConfig["type"]) => {
      return (
        msg: Omit<Message.MsgConfig, "type"> | Message.MsgConfig["content"]
      ) => {
        updateMessages({
          ...(typeof msg === "object" && msg?.hasOwnProperty("content")
            ? (msg as Message.MsgConfig)
            : { content: msg as string }),
          type,
        });
      };
    },
    [updateMessages]
  );

  return React.useMemo(
    () => ({
      dispatch: updateMessages,
      success: createMessageDispatcherOfType("success"),
      error: createMessageDispatcherOfType("error"),
      info: createMessageDispatcherOfType("info"),
      warning: createMessageDispatcherOfType("warning"),
      pending: createMessageDispatcherOfType("pending"),
      clear: clearMessages,
    }),
    [updateMessages, clearMessages, createMessageDispatcherOfType]
  );
}