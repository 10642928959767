import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { parseRemoteError } from "./errors";
import { queryString } from "./queryString";
dayjs.extend(utc);

const EMPTY_RESPONSE = null;

export declare namespace FetchData {
  interface Args extends Omit<RequestInit, "method" | "body"> {
    url?: string;
    type?: RequestInit["method"];
    isUpload?: boolean;
    body?: any;
    query?: Record<string, any>;
    encodeBodyAsFormData?: boolean;
    authToken?: string;
  }

  export type ApiFunction<T> = Args | T;

  type PaginatedResponse<Item> = {
    data: Item[];
    next_cursor: string | null;
  };
  type PaginatedApiFunction<Item> = Args | PaginatedResponse<Item>;
}

export async function fetchData<T = any>({
  url = "",
  type = "GET",
  body = null,
  query,
  headers = {},
  encodeBodyAsFormData = false,
  isUpload = encodeBodyAsFormData,
  authToken,
  ...rest
}: FetchData.Args): Promise<T> {
  try {
    headers = {
      "Content-Type": "application/json",
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...headers,
    };

    const args: RequestInit = { method: type, headers, credentials: 'include', ...rest };

    if (query) {
      try {
        const urlQuery = url.match(/\?.*/)?.[0] ?? null;
        const parsedUrlQuery = urlQuery ? queryString.parse(urlQuery) : {};
        // const urlQuery = url ? queryString.parse(url.match(/\?.*/)?.[0]) : {}
        // replace query if it exsists
        // otherwise just append new query
        url = url = url.replace(/(\?.*|)/, "");

        const stingifiedQuery = queryString.stringify({
          ...parsedUrlQuery,
          ...query,
        });

        url = [url, stingifiedQuery].join("?");
      } catch (e) {
        console.log(e);
      }
    }

    // auto format dates to UTC strings,
    // which btl backend is able to parse
    for (const key in body) {
      const val = body[key];
      if (val instanceof dayjs) {
        body[key] = dayjs(val as ReturnType<typeof dayjs>)
          .utc()
          .format();
      }
    }

    if (body && encodeBodyAsFormData) {
      const fd = new FormData();
      const attrs: Record<string, any> = {};
      for (const key in body) {
        const val = body[key];
        if (val instanceof Blob) {
          fd.append(key, body[key]);
        } else {
          attrs[key] = val;
        }
      }
      fd.append("attrs", JSON.stringify(attrs));
      body = fd;
    }

    if (body && !isUpload) {
      args.body = JSON.stringify(body);
    }

    if (body && isUpload) {
      // if uploading, do not send content-type header
      // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
      // @ts-expect-error
      delete args.headers["Content-Type"];
      args.body = body;
    }
    const res = await fetch(url, args);
    const status = res.status;
    const text = await res.text();
    const data = text ? JSON.parse(text) : EMPTY_RESPONSE;

    // user token expired or invalid
    if (status === 401) {
      // alert("Your session has expired. Please log in again.")
      throw "Invalid or expired token";
    }

    if (![200, 201].includes(status)) {
      if (data?.errors) {
        throw data.errors;
      }
      throw data?.error ?? "Unknown error";
    }

    return data;
  } catch (e) {
    throw parseRemoteError(e);
  }
}
