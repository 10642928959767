import { useModule } from "../utils/module";
import { Select } from './wrapped';

export function CountrySelector(
  props: Pick<JSX.IntrinsicElements["select"], "name" | "defaultValue">
) {
  const ccl = useModule(() => import("country-code-lookup"))?.default;

  return (
    <Select {...props}>
      {ccl?.countries.map((country) => (
        <option value={country.iso2}>{country.country}</option>
      ))}
    </Select>
  );
}
