import { config } from "../config";
import { API } from "../types/api";

function joinPath(...segments: string[]) {
  return segments.map((seg) => seg.replace(/^\/|\/$/g, "")).join("/");
}

function api(route: string) {
  return `${config.apiUrl}/${joinPath("api", "v1", route)}`;
}

function s3Asset({ key, filename }: API.Asset) {
  const s3Url = `https://${config.s3AssetBucket}.s3.${config.s3AssetBucketRegion}.amazonaws.com`;
  return `${s3Url}/${key}/${filename}`;
}

export const urls = {
  api,
  s3Asset,
};
