import { StripeError as StripeErrorInterface } from "@stripe/stripe-js";

export class GeneralError {
  name: string;
  message: string;

  constructor(name: string, message: string) {
    this.name = name;
    this.message = message;
  }

  toString() {
    return this.message;
  }

  format() {
    return this.message;
  }
}

export class StripeError extends GeneralError {
  code: string;

  constructor(stripeErr: StripeErrorInterface) {
    super("StripeError", stripeErr.message ?? "");
    this.code = stripeErr.code ?? "";
  }
}

export class BookingError extends GeneralError {
  constructor(err: Error) {
    super("BookingError", err.message);
  }
}

export class UserNotFoundError extends GeneralError {
  constructor(err: Error) {
    super("UserNotFoundError", err.message);
  }
}

export class BadRequestError extends GeneralError {
  constructor(err: Error) {
    super("BadRequestError", err.message);
  }
}

// TODO: what type is error param
export class UniqueConstraintError extends GeneralError {
  fields: any;

  constructor(err: any) {
    super("UniqueConstraintError", err.message);
    this.fields = err.fields;
  }
}

export const parseRemoteError = (e: StripeError | Error | any) => {
  if (e.name) {
    switch (e.name) {
      case "StripeError":
        return new StripeError(e);
      case "BookingError":
        return new BookingError(e);
      case "UserNotFoundError":
        return new UserNotFoundError(e);
      case "UniqueConstraintError":
        return new UniqueConstraintError(e);
      case "BadRequestError":
        return new BadRequestError(e);
    }
  }
  if (e.message) {
    return new GeneralError(e.name ?? "Error", e.message);
  }
  return new GeneralError(e.name ?? "Error", String(e));
};

export default {
  GeneralError,
  StripeError,
  BookingError,
  UserNotFoundError,
  UniqueConstraintError,
  parseRemoteError,
};
