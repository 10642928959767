export const partnersConfig: Record<
  "equinox" | "toppingRose" | "americanExpress",
  { routerRoot: string }
> = {
  equinox: {
    routerRoot: "/equinox-hamptons",
  },
  toppingRose: {
    routerRoot: "topping-rose",
  },
  americanExpress: {
    routerRoot: "/american-express",
  },
} as const;
