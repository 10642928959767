const {
  REACT_APP_API_URL,
  REACT_APP_S3_ASSETS_BUCKET,
  REACT_APP_S3_ASSETS_BUCKET_REGION,
  REACT_APP_ROOT,
  REACT_APP_SEGMENT_WRITE_KEY,
  REACT_APP_PARENT_SITE
} = process.env;

export const config = {
  segmentWriteKey: REACT_APP_SEGMENT_WRITE_KEY,
  parentSite: REACT_APP_PARENT_SITE ?? "//breakthelove.com",
  appRoot: REACT_APP_ROOT?.replace(/\/$/, "") ?? '',
  apiUrl: (REACT_APP_API_URL ?? "http://localhost:4000").replace(/\/$/, ""),
  stripePublishableKey: "pk_test_PpCK9DI3ygU9xRh4wygxOQJo00EDHwDiDT",
  s3AssetBucket:
    REACT_APP_S3_ASSETS_BUCKET ?? "breakthelove-development-assets-1",
  s3AssetBucketRegion: REACT_APP_S3_ASSETS_BUCKET_REGION ?? "us-east-2",
  colors: {
    background: "var(--btl-background, white)",
    danger: "red",
    textMuted: "gray",
    border: "var(--btl-border, gray)",
    accent: "var(--btl-accent, black)",
    accentContrastText: "var(--btl-accent-contrast-text, white)",
    warning: "yellow",
    success: "green",
    text: "var(--btl-text, black)",
  },
};
